@media screen and (max-width:1501px) {
  .main-title-box .title-pro-right ul li a {
    display: flex;
    font-family: DM Sans;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #f6f6f6;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px !important;
    text-decoration: none;
    color: rgba(43, 54, 116, 1);
    padding: 6px 16px;
    font-size: 15px;
  }

  .user-name-contain>.user-name>h4,
  .user-name-contain>.user-name>span .listing-details-card>h2 {
    font-size: 20px;
  }

  .user-name-contain>.user-name>h4,
  .user-name-contain>.user-name>span {
    font-size: 20px;
  }

  .main-title-box .title-pro-right ul li a {
    font-size: 12px;
    padding: 8px 15px;
  }

  #sidebar-main {
    width: 230px;

  }

  .main-layout {
    margin-left: 230px;

  }

  .suspend-btn {
    min-width: 160px;
  }

  .account-card {
    min-width: 200px;
  }

  .search-bar>div {

    margin: 5px 0;
  }

  .item-images>img {
    width: 150px;
    height: 150px;
  }

  .title-pro-right>div>img {
    width: 35px;
    height: 35px;
  }
  
}

@media screen and (max-width:1201px) {
  .suspend-btn{
    min-width: 130px;
    line-height: normal;
  }
  .user-name-contain>.user-name>h4,
  .user-name-contain>.user-name>span .listing-details-card>h2 {
    font-size: 14px;
  }

  .user-name-contain>.user-name>h4,
  .user-name-contain>.user-name>span {
    font-size: 14px;
  }

  .main-title-box .title-pro-right ul li a {
    font-size: 10px;
    padding: 8px 12px;
  }
  .main-title-box .title-pro-right ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
}
.user-name {
  display: block;

}
.title-head-left>span {
 
  font-size: 10px;

}
.wallet-tabs {
  gap: 15px;
}
}
@media screen and (max-width:991px) {
  .suspend-btn{
    min-width: 160px;
    /* line-height: normal; */
  }
  .wallet-tabs {
    gap: 20px;
}
}

@media screen and (max-width:575px) {
.privacy_text h2 {
  font-size: 22px;
}
.privacy_text h4 {
  font-size: 17px;
}
.privacy_text h3 {
  font-size: 19px;
}
.privacy_text h1 {
  font-size: 22px;
}

}