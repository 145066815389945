body {
  background-color: #f7f7f7;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15) !important;
}

#sidebar-main {
  width: 280px;
  z-index: 1001;
  background: #ffffff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
/* .sidebar-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
} */
.sidebar-logo {
  padding: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.sidebar-list-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  /* gap: 300px; */
  height: 80vh;
  padding: 10px;
}

.sidebar-list-menu li a {
  text-decoration: none;
  font-size: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  /* position: relative; */
  transition: all 0.4s;
  border-radius: 10px;
  color: #c0c0c0;
  font-weight: 500;
  font-family: Raleway;
  line-height: normal;
}

.sidebar-list-menu li {
  width: 100%;
  margin-bottom: 5px;
}

.sidebar-list-menu li a i {
  display: inline-block;
  min-width: 2.15rem;
  padding-bottom: 0;
  font-size: 16px;
  line-height: 1.40625rem;
  display: flex;
  color: #7f8387;
  transition: all 0.4s;
}

.tab_active svg path {
  fill: #000;
  /* background: #000; */
}

.tab_active span {
  color: #222;
}

.main-layout {
  margin-left: 280px;
  overflow: hidden;
}

.page-content {
  padding: calc(0px + 24px) calc(24px / 2) 60px calc(24px / 2);
}

.main-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.title-head-left > span {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  color: #8d8d8d;
}

.title-head-left h4,
.user-name > span {
  margin: 0;
  font-family: Raleway;
  font-size: 32px;
  font-weight: 700;
  color: #222;
}

.title-pro-right > div {
  background: #fff;
  /* width: 120px; */
  padding: 8px 15px;
  border-radius: 30px;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  display: flex;
  justify-content: center;
  gap: 18px;
  align-items: center;
  box-sizing: border-box;
}

.page-content .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: 20px;
  height: 100%;
}

.title_text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #222;
}

.commom-tables tr th {
  min-width: 100px;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

.table th {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  padding: 8px 8px;
  color: #c0c0c0;
  text-wrap: nowrap;
}

tbody.td_color tr td {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #222;
  padding: 8px 8px;
}

tbody.td_color {
  border: 1px solid transparent;
}

tbody.td_color tr td a {
  color: #222;
  font-size: 14px;
  text-decoration: none !important;
}

/* -----------Sign-In page------------- */
.signin-content {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.form-heading {
  margin-bottom: 25px;
}

.form-heading h1 {
  font-family: DM Sans;
  font-size: 36px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  margin-bottom: 5px;
}

.form-heading p {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgb(82 82 82);
}

.label-form {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  margin-bottom: 10px;
}

.input-form {
  border: 1px solid rgba(224, 229, 242, 1);
  border-radius: 15px;
  min-width: 410px;
  /* width: 100%; */
  padding: 15px 12px;
}

.input-form:focus {
  outline: none;
  /* border: 1px solid rgba(224, 229, 242, 1) ; */
}

::placeholder {
  font-family: DM Sans;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgb(82 82 82) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.signin-form div {
  margin-bottom: 24px;
}

.password-input::after {
  /* content: ''; */
  /* background-image: url(../images/eye.svg); */
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  top: 8px;
  left: -10%;
  display: inline-flex;
}
.password-input {
  position: relative;
}

.password-input .imgage {
  position: absolute;
  right: 13px;
  width: 20px;
  top: 20px;
}

.logged-in-forget {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  width: 410px;
}

.logged-in {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logged-in label {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
}

.logged-in input {
  width: 16px;
  height: 16px;
  accent-color: #000 !important;
}

.forget-password {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  text-decoration: none;
}

.submit-btn {
  width: 410px;
  border-radius: 16px;
  padding: 20px 0px;
  text-align: center;
  background-color: #222;
  color: white;
  border: 1px solid transparent;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.02em;
  transition: background-color 0.3s ease-in-out;
}

.submit-btn:hover {
  /* background-color: rgb(32, 41, 88); */
}

.wedged-logo {
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: white; */
  min-height: 100vh;
}

/* ------Listings Page------- */
.search-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.search-bar > div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.input-common-properties {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 12px;
  padding: 0;
  padding: 8px 15px;
  outline: none;
}

.input-common-properties,
.input-common-properties::placeholder {
  font-family: DM Sans !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: -0.02em !important;
  text-align: left !important;
  color: #000 !important;
}

/* ------------Users Page------------ */
.total-card {
  display: flex;
  justify-content: start;
  gap: 15px;
}

.bg-circle {
  width: 56px;
  height: 56px;
  background-color: #f7f7f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-card div p {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgb(82 82 82);
  margin: 0;
}

.total-card div h3 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  margin: 0;
}

.td_color tr td img {
  width: 30px;
  height: 30px;
  border-radius: 40px;
}
/* .id-field::after {
  content: '';
  background-image: url(../images//greydownarrow.svg);
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: bottom right;
  display: inline-block !important;

} */

.export {
  display: flex;
  justify-content: end;
}

.export-btn {
  width: 112px;
  border-radius: 12px;
  border: 1px solid transparent;
  background: #222;
  color: white;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  padding: 8px;
}

/* ------------Feedback page------------ */
.issue-detail {
  width: 700px !important;
}

.profile-card {
  display: flex;
  align-items: end;
  gap: 15px;
  padding: 37.5px 20px;
}

/*-------------- User-Overview Page --------------*/
.user-name-contain {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-name-contain > .left-arrow-icon {
  background: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 8px;
}

.user-name {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-name > span {
  color: #222;
}

.profile-card > div > img,
.seller-img > img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  object-fit: cover;
}

.useroverview-h3-p {
  display: flex;
  flex-direction: column;
  text-align: left !important;
}

.useroverview-h3-p > h3 {
  font-size: 18px !important;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  /* text-align: left; */
  color: #222;
  margin: 0;
}

.useroverview-h3-p > p {
  font-size: 14px !important;
  text-align: left !important;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: rgb(82 82 82);
  margin: 0;
}

.account-actions {
  display: flex;
  justify-content: center !important;
}

.wallet-heading {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  margin-bottom: 15px;
}

.wallet-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}

.wallet-card {
  border: 1px solid transparent;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0;
  border-radius: 12px !important;
  min-width: 145px;
}

.wallet-card > .card-body {
  padding: 10px;
}

.wallet-card > div > p {
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #b8b8b8;
  margin: 5px;
}

.wallet-card > div > h3 {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  margin: 0;
}

.details-card > .details-header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.form-details-input {
  /* min-width: 100px; */
  /* width: 100%; */
  /* padding-top: 10px !important; */
  padding-bottom: 0 !important;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  border: 0.5px solid transparent !important;
}

.form-details-input,
.form-details-input::placeholder {
  font-family: DM Sans !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: -0.02em !important;
  /* color: #000 !important; */
  /* padding-top: 20px !important; */
}

/* .recent-likes-card {} */

.recent-likes {
  font-size: 18px;
}

.form-details-label {
  font-family: DM Sans !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  letter-spacing: -0.02em !important;
  /* text-align: left; */
  color: #b8b8b8 !important;
  /* padding-bottom: 0 !important; */
  padding-top: 10px !important;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3rem + calc(var(--bs-border-width) * 2));
  /* line-height: 1.25; */
}

.form-control:active {
  outline: none !important;
  /* border: none !important; */
}

.form-details-textarea {
  /* width: 100% !important; */
  padding-top: 20px !important;
  resize: none;
  border: none;
}

.textarea-bio > .form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(9rem + calc(var(--bs-border-width) * 2));
  min-height: calc(9rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
  scrollbar-width: none;
}

.address-textarea {
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
}

.address-textarea > .form-floating > textarea {
  scrollbar-width: thin;
}

.address-textarea > .form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: auto !important;
  min-height: calc(6.6rem + calc(var(--bs-border-width) * 2));
  /* line-height: 1.25; */
}

.dob-label {
  padding-top: 15px !important;
}

.account-card > div > h4 {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  margin: 0;
}

.account-card {
  min-width: 240px;
  padding: 0 !important;
}

.account-card > .card-body {
  padding: 8px 10px !important;
}

.account-actions-card > div > button {
  color: #222;
}

.suspend-btn {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #222;
  border: 0.5px solid transparent;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 220px;

  border-radius: 16px;
  background-color: white;
  padding: 8px 15px;
}

.usertransaction-card {
  width: 135px !important;
}

.usertransaction-card > .card-body {
  padding: 5px !important;
}

/* -----------Listing DEtails page ----------*/
.listing-details-card {
  justify-content: space-between !important;
  padding: 10px 20px !important;
}

.listing-details-card > h2 {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  margin: 0;
}

.itemdetails-likes > div {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25) !important;
  width: 60px !important;
  gap: 5px !important;
}

.itemdetails-likes > p {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: right;
}

.item-images {
  display: flex;
  padding: 20px;
  gap: 20px;
  overflow: auto;
  scrollbar-width: none;
}

.item-images > img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.account-card-height {
  min-height: 248px;
}
.details-card-height {
  min-height: 585px;
}
.form-inputs {
  width: 47% !important;
}
.input-width {
  width: 50%;
}
.padding_ {
  padding: 10px 20px;
  display: flex;
  /* gap: 40px; */
  /* justify-content: space-between; */
}

.seller-img {
  display: flex !important;
  justify-content: start;
}

.seller-card {
  min-height: 165px;
}

.buyers-card .no-content {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyers-card .no-content p {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  /* text-align: center; */
  color: #b8b8b8;
  margin: 0;
}
.offers-card {
  min-height: 378px !important;
}

.offers-table > thead > tr > th {
  border: 0;
}

.offers-table > tbody > tr > td > p {
  margin: 0;
}

.list-item-contain .left-circle {
  height: 55px;
  width: 55px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 50%;
}

.listing-status-contain ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 55px;
}

.list-item-contain .list-left-data > div {
  line-height: normal;
}

.list-left-data > div > p {
  margin: 0;
  font-size: 15px;
  color: #222;
  font-weight: 700;
  padding: 0;
}

.list-left-data > div > span {
  margin: 0;
  font-size: 13px;
  color: rgba(176, 187, 213, 1);
  font-weight: 500;
}

.listing-status-contain li {
  list-style: none;
  padding: 0;
}

.listing-status-contain {
  margin: 20px 0px;
}

.list-item-contain .list-left-data {
  display: flex;
  gap: 10px;
  align-items: center;
}

.list-item-contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-input-height {
  min-height: 110px !important;
}

.purchase-card {
  min-height: 200px;
}

.min-width-input-purchase {
  min-width: 300px !important;
  width: 50%;
}

.min-vh-80 {
  min-height: 80vh;
}

.listing-status-contain .ad-progress {
  position: relative;
}

.listing-status-contain .ad-progress::before {
  content: "";
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  height: 130px;
  width: 0px;
  /* background: aqua; */
  margin: auto;
  border: 1px dashed rgba(217, 217, 217, 1);
}

.list-item-contain > div svg {
  height: 30px;
  width: 30px;
}

.list-item-contain > div svg {
  height: 30px;
  width: 30px;
}

.listing-status-contain .ad-green-bg .left-circle {
  background-color: rgba(114, 166, 59, 1);
}

.listing-status-contain .ad-green-bg .ad-progress::before {
  border: 1px dashed rgba(114, 166, 59, 1);
}

.ad-green-bg .list-item-contain > div svg circle {
  fill: rgba(237, 103, 60, 1);
}

.main-title-box .title-pro-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.main-title-box .title-pro-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
}

.main-title-box .title-pro-right ul li button {
  font-weight: 700;
  font-size: 14px;
  display: flex;
  font-family: DM Sans;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px !important;
  text-decoration: none;
  color: #222;
  padding: 10px 20px;
}

.main-title-box .title-pro-right button.active {
  background-color: #222;
  color: #fff;
}

/* -----------Create Notification----------- */
.schedule-btn {
  margin-top: 20px;
  text-align: end;
}

.schedule-btn > button {
  width: 240px;
  padding: 13px;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(2px) translateX(0.15rem) !important;
}

.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff00 !important;
  border-radius: var(--bs-border-radius);
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 16px;
  padding-bottom: 0.625rem;
}

.dashboard-data-no p {
  color: #b8b8b8;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.4px;
}

.dashboard-data-no {
  position: absolute;
  top: 50%;
  left: 50%;
}
.checkbox-input {
  display: flex;
  align-items: center;
}
.checkbox-input > input,
.radio-input > input {
  accent-color: #222;
}

.details-card-height button {
  background-color: #222;
  color: #fff;
  border: 0;
}

.user-pro-upld {
  position: relative;
}

.user-pro-upld input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  left: 0;
  top: 0;
}

.no-data-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-data-found p {
  margin: 0;
  font-weight: 500;
  color: #5a5a5add;
}

.delt-modal-txt {
  margin: 0;
  font-size: 20px;
}

.border-none {
  border: 0;
}

.action-btn-set {
  display: flex;
  gap: 10px;
}

.action-btn-set button {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #222;
  padding: 8px 8px;
  border: 0;
  padding: 0;
  background-color: transparent;
}

.pagination_custom {
  list-style: none;
  height: 31.5px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
  cursor: pointer;
}

.pagination_custom .selected {
  color: #ffffff !important;
  background: #222;
  border-color: #222;
}

.pagination_custom li {
  margin: 0 10px;
  border: 1px solid;
  padding: 3px 10px;
  border-radius: 5px;
  border-color: #cfc6c6;
  font-size: 17px;
}

.pagination_custom li a {
  color: #494949;
  font-size: 16px;
  padding: 0px 4px;
  font-weight: 600;
  text-decoration: none;
  height: 30px !important;
  display: flex;
  align-items: center;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination_custom .selected a {
  font-size: 16px;
}

.privacy_text {
  font-family: Raleway;
}

.privacy_text h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.privacy_text h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-size: 16px;
  font-family: Raleway;
}

.privacy_text p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: Raleway;
}

.header-logo {
  text-align: center;
}
.header-logo h2 {
  margin-top: 0;
  font-family: Raleway;
}

.header-logo a img {
  height: 100px;
}

.privacy_text h1 {
  font-size: 40px;
  font-family: Raleway;
  font-weight: 700;
}

.privacy_text h4 {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 16px;
  font-family: Raleway;
}

.privacy_text h2 {
  padding-top: 9px;
  font-size: 33px;
  font-weight: 700;
  font-family: Raleway;
  padding-bottom: 0px;
}

.privacy_text h3 {
  font-size: 22px;
  font-weight: 700;
  font-family: Raleway;
}

.privacy_text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  font-family: Raleway;
}

.privacy_text {
  padding-bottom: 50px;
  padding-top: 50px;
}

.container-tem {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sub_points {
  list-style: decimal;
  padding: 0 0 0 25px;
}

.create-post h2 {
  font-size: 20px;
  margin: 0px;
  font-weight: 600;
  color: #2f2b3de5;
  font-family: Raleway;
}

.privacy_text h4 span {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  font-family: Raleway;
}

@media (min-width: 768px) {
  .container-tem {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container-tem {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container-tem {
    width: 1180px;
  }
}

.pagination_custom {
  list-style: none;
  height: 31.5px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
  cursor: pointer;
}

.pagination_custom .selected {
  color: blue;
}

.pagination_custom li {
  margin: 0 10px;
  border: 1px solid;
  padding: 3px 10px;
  border-radius: 3px;
  border-color: #cfc6c6;
  font-size: 17px;
}

.pagination_custom .selected a {
  font-size: 16px;
}

.pagination_custom .selected a {
  font-size: 16px;
}

.sidebar-logo img {
  width: 150px;
  height: auto;
}

.no-wrap {
  white-space: nowrap;
}

.pagination-icon-label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pagination-icon-label svg {
  height: 12px;
  width: 12px;
  line-height: normal;
}
.pagination-icon-label p {
  line-height: 10px;
  margin: 0;
}

.tab_active a {
  background: #222;
}

.tab_active a svg path {
  fill: #fff !important;
}
.tab_active a span {
  /* background: #000; */
  color: #fff !important;
}

.sidebar-list-menu li a svg {
  height: 18px;
}

.sidebar-list-menu li a svg path {
  fill: #c0c0c0;
}

.card.offer-card {
  height: 100%;
}

.bg-circle i svg path {
  fill: #000;
}

.select-wi-label select:focus {
  outline: none !important;
}

.select-wi-label select {
  border: 0;
  width: 100%;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
  margin: 0;
}

.chat-show-bot {
  width: calc(100% - 40%);
  margin: auto;
}

.chat-show-bot ul {
  list-style: none;
  padding: 0;
}

.chat-bot-view {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.chat-bot-view > div {
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 10px;
}

.right-side-bot {
  display: flex;
  justify-content: end;
}

.right-side-bot {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.chat-show-bot {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

#category:focus option:first-of-type {
  display: none;
}

.height-fixed-table {
  height: 180px;
  scrollbar-width: none;
}

.new-text-area-address {
  font-family: DM Sans !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: -0.02em !important;
  color: #000 !important;
  padding-top: 10px !important;
  padding-left: 0px !important;
}

.img-gellar-set {
  display: flex !important;
  padding-top: 10px !important;
  flex-wrap: nowrap !important;
  gap: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.img-gellar-set > img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 5px;
  min-width: 120px;
}

.offer-table-overflow {
  height: 280px !important;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}
.add-new-height {
  height: auto;
  min-height: 602px;
}

.list-left-data p {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.02em;
  padding: 8px 8px;
  color: #c0c0c0;
  line-break: auto;
}

.accordion-body img {
  max-width: 100%;  
  height: auto;  
  display: block; 
  margin: 0 auto; 
}

.dispute-box button {
  max-width: max-content;
  border-radius: 12px;
  border: 1px solid transparent;
  background: #222;
  color: white;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  padding: 6px 23px;
}
.dispute-box>div {
  text-align: right;
  margin-bottom: 10px;
}

.add-new-dispute {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}